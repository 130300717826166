:root {
  --primary: #3db39e;
  --secondary: #060316;
}

@font-face {
  font-family: "Tahoma";
  src: url("../fonts/TAHOMA_0.eot");
  src: url("../fonts/TAHOMA_0.svg") format("svg"),
    url("../fonts/TAHOMA_0.ttf") format("truetype"),
    url("../fonts/TAHOMA_0.woff") format("woff"),
    url("../fonts/TAHOMA_0.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tahoma";
  src: url("../fonts/TAHOMAB0.eot");
  src: url("../fonts/TAHOMAB0.svg") format("svg"),
    url("../fonts/TAHOMAB0.ttf") format("truetype"),
    url("../fonts/TAHOMAB0.woff") format("woff"),
    url("../fonts/TAHOMAB0.woff2") format("woff2");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Tahoma";
  src: url("../fonts/TahomaRegularfont.eot");
  src: url("../fonts/TahomaRegularfont.svg") format("svg"),
    url("../fonts/TahomaRegularfont.ttf") format("truetype"),
    url("../fonts/TahomaRegularfont.woff") format("woff"),
    url("../fonts/TahomaRegularfont.woff2") format("woff2");
  font-weight: 400;
  font-style: 400;
}

body {
  font-family: system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: normal;
  font-size: 12px;
  background: #f0ece1;
}

.text-primary {
  color: #2789ce !important;
}

img {
  max-width: 100%;
}

/* .container-fluid {
  max-width: 1350px !important;
} */

.top-header {
  background: #ffcc2f;
  color: #fff;
  padding: 11px 0px;
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  padding-left: 16.666667%;
}
.userip {
  background: green;
  padding: 3px 5px;
  color: #fff;
  border-radius: 6%;
}

.top-header .logo img {
  /* filter: brightness(0); */
  width: 150px;
}

.top-header-owner li {
  display: inline-block;
  margin-right: 18px;
}

.top-header-owner li strong {
  font-weight: 500;
  color: #000;
}

.top-header-owner li span {
  padding: 2px 7px;
  color: #fff;
  margin-right: 4px;
  display: inline-flex;
  justify-content: center;
  border-radius: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.top-header-owner li span:not(.top-header-owner li .btn span) {
  background-color: rgba(0, 0, 0, 1);
  color: #ffcc2f;
}

.top-header-owner li {
  color: #fff;
  font-size: 13px;
}

.top-header-owner li a {
  color: #fff;
  padding: 0;
  margin-left: 10px;
}

.top-header-owner li .btn span {
  font-size: 16px;
  margin-right: 0.5rem;
  height: 2rem;
  border-radius: 0.125rem;
  background-color: #000;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: #ffcc2f;
  align-items: center;
}

.total-balance-sec ul li {
  display: inline-block;
  max-width: 16.66%;
  flex: 0 0 16.66%;
  border-right: 1px solid rgba(156, 163, 175, 1);
  padding: 0px 16px;
  /* background-color: #0dcaf0; */
}

.total-balance-sec ul {
  padding: 12px 0;
  display: flex;
  border-bottom: 1px solid rgba(156, 163, 175, 1);
  background: rgba(243, 244, 246, 1) !important;
}

.total-balance-sec ul li strong {
  font-size: 20px;
  color: #000;
  line-height: 20px;
  font-weight: 800;
}

.total-balance-sec ul li dt {
  font-size: 12px;
  margin: 0 0 5px 0;
  color: rgba(107, 114, 128, 1) !important;
  font-weight: normal;
}

.total-balance-sec ul li span {
  /* color: red; */
}

.total-balance-sec ul li:first-child {
  border-left: none;
  /* padding-left: 0px; */
}

.account-table .table th {
  color: #fff;
  background-color: rgba(75, 85, 99, 1);
  border-width: 1px 0;
  padding: 0.5rem;
  font-size: 12px;
  font-weight: normal;
  font-weight: 700;
}

.profit_loss_table .table th {
  text-align: left !important;
  padding: 3px 10px;
}
.profit_loss_table .table td {
  text-align: left !important;
  padding: 3px 10px;
}

.account-table td a {
  font-size: 12px;
  color: #000;
  /* text-decoration:none;  */
}

.change-password-sec .theme_dark_btn {
  min-width: 110px;
  padding: 3px 10px;
}
.account-table td a span {
  background: #fde68a;
  color: #b45309;
  padding: 1px 5px;
  text-decoration: none;
  display: inline-block;
  border-radius: 0;
  font-size: 0.75rem;
  margin-right: 8px;
  font-weight: 600 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #eee;
  color: #333;
  outline: none;
}

.w-80 {
  width: 80%;
}

.account-table .total-table-balance td {
  background-color: var(--primary);
}

.account-table td a span {
  font-weight: bold;
}

.profit_loss_table td a span {
  background: none !important;
}

.account-table td a .status-suspend1 {
  background-color: #f2e2e6;
  border: 1px solid #deb6c0;
  color: #d0021b;
  font-weight: 700;
  padding: 2px 10px;
  min-width: 80px;
  text-align: center;
  border-radius: 4px;
}

.account-table td {
  border-top: 1px solid #d1d5dbcc !important;
  border-bottom: 1px solid #d1d5dbcc;
  padding: 6px 10px;
  vertical-align: middle;
  background-color: #fff;
}
.list-unstyled {
  margin-top: 5px;
}
.block-market-table td {
  padding: 3px 10px;
}
.block-market-table .form-switch .form-check-input {
  width: 34px;
  height: 20px;
  margin: 0;
}
.block-market-table td {
  border-bottom: 1px solid #00000063;
}

.block-market-table td i {
  cursor: pointer;
  background: #0dcaf0;
  border-radius: 50%;
  padding: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.account-tabel-style td {
  background-color: transparent !important;
}

.check_balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banking-table > td {
  border: 1px solid #5642424f;
}

.banking-table > th {
  border: 1px solid #555 !important;
}

.account-table td .status-active,
.account-table td .status-suspend,
.account-table td .status-locked {
  padding: 0px 10px 0px 20px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
}

.white_btn {
  background: linear-gradient(180deg, #fff 0%, #eee 89%);
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 5px;
  color: #333;
  box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid #bbb;
  cursor: pointer;
}

.expand-balance table {
  width: calc(100% - 90px);
  margin-left: 90px !important;
}

.account-table td .status-locked {
  background-image: linear-gradient(-180deg, #9ab6ce 0%, #536174 100%);
  color: #fff !important;
  border: 1px solid #efefef;
}

.account-table td .status-active {
  background-color: rgba(209, 250, 229, 1);
  color: rgba(6, 95, 70, 1);
  font-size: 0.75rem;
  font-weight: 600;
}

.account-table td .status-suspend {
  background-image: linear-gradient(-180deg, #db2828 0%, #921313 100%);
  border: 1px solid red;
  color: #fff;
}

.account-table td.action_link .btn {
  background: #fffbeb;
  box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid rgba(180, 83, 9, 0.2);
  border-radius: 0.125rem;
  color: #1e1e1e;
  font-weight: bold;
  margin-right: 4px;
  padding: 0.3rem 0.5rem;
  margin-left: 3px;
  line-height: 1;
}

.account-table td.action_link .btn i {
  font-size: 16px;
}

.find-member-director .btn:hover {
  background-color: rgba(254, 243, 199, 1);
}

.main-header {
  background: linear-gradient(180deg, #ffb80c 8%, #ffb80c) !important;
  color: #fff;
}

.account-table td .status-active::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #059669;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
}

.main-header .navbar-nav .nav-link {
  color: #fff;
}

.main-header .navbar-nav .nav-link {
  border-bottom: thin solid #ffffff4d;
  color: #ffffffe6;
  font-weight: 400;
  padding: 0.75rem;
  transition: 0.4s;
  font-size: 12px;
}
.main-header .container-fluid {
  padding: 0 !important;
}
.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  border-top: 0.5em solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  position: absolute;
  right: 10px;
  top: 18px;
  vertical-align: 0.255em;
}

.navbar-expand-xl {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.navbar {
  padding: 0;
}

.main-header .navbar-nav .nav-link.active1,
.main-header .navbar-nav .nav-link:hover {
  background: #ffffff33;
  text-decoration: none;
}
.main-header .navbar-nav .nav-link:hover {
  /* text-decoration: underline; */
}

.main-header-time-zone li {
  display: inline-block;
  margin-right: 13px;
  font-size: 13px;
  color: #fff;
  border-bottom: thin solid hsla(0, 0%, 100%, 0.3);
  color: #fff;
  font-weight: 400;
  padding: 0.75rem;
  transition: 0.4s;
  width: 100%;
}

.main-header-time-zone li span {
  color: #fff;
}

.main-header-time-zone li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.main-header-time-zone li a i {
  margin-left: 3px;
}

.main-header .dropdown-menu {
  border-radius: 0px;
}

/* .breadcum-sec .bredcum-sec-main {
  background: #000;
  color: #fff;
  padding: 8px 11px;
  border-radius: 4px;
} */

.bredcum-sec-main h5 {
  font-size: 15px;
  font-weight: 400;
}

.dropdown-menu {
  border-radius: 0px;
}

.breadcum-sec marquee {
  width: 88%;
  margin-left: 19px;
  color: rgba(147, 197, 253, 1);
  font-weight: 600;
  font-size: 14px;
}

.breadcum-sec .bredcum-sec-main {
  color: #fff;
  height: 2.5rem;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(148, 163, 184, 1);
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, 1);
  display: flex;
  align-items: center;
}

.breadcum-sec marquee a {
  text-decoration: none;
  color: #fff;
}

.bredcum-sec-main h5 {
  font-size: 16px;
  font-weight: 500;
  background-color: rgba(30, 41, 59, 1) !important;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border-right: 1px solid rgba(100, 116, 139, 1) !important;
  height: 2.5rem;
  line-height: 2.5rem;
}

.find-member-sec form {
  display: flex;
  align-items: center;
  width: 100%;
}

.find-member-sec form input.form-control {
  padding: 6px 28px 6px 9px;
  min-width: 200px;
  border-radius: 0.125rem;
  height: 1.75rem;
  box-shadow: none;
}

.find-member-sec form input.form-control::placeholder {
  font-size: 12px;
  color: rgb(167, 167, 167);
  font-weight: 500;
}

.find-member-sec .form-select {
  padding: 4px 10px;
  min-width: 134px;
  font-size: 12px;
  border-color: #d1d5db;
  height: 1.75rem;
  box-shadow: none;
  text-transform: uppercase;
}

.find-member-sec form .search-btn {
  /* position: absolute; */
  /* top: 50%; */
  /* right: 3px; */
  width: max-content;
  height: 28px;
  border: 0;
  border-radius: 2px;
  /* transform: translateY(-50%); */
  background-color: #ffcc2f;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  padding: 0 8px;
  color: #000;
  margin-left: 10px;
}

.find-member-sec form .position-relative {
  width: 24rem;
}

.find-member-sec form .fa-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
  color: #0000007a;
  font-size: 14px;
}

.find-member-director .btn,
.agent-path ul,
.theme_light_btn {
  /* background-image: linear-gradient(180deg, #ed1b24 0%, #f19d9d 100%); */
  box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid rgba(180, 83, 9, 0.2);
  border-radius: 4px;
  color: #1e1e1e;
  /* font-weight: bold; */
  font-size: 12px;
  /* line-height: 28px; */
  padding: 0.25rem 0.5rem;
  margin-right: 10px;
  background: rgba(255, 251, 235, 1);
}

.account-table td .btn {
  margin: 0 2px;
}

.bet_status .bet-sec .form-select {
  flex: 1;
}

.bet_status .bet-sec label {
  margin-right: 10px;
}

.theme_dark_btn,
.green-btn {
  background: #ffcc2f !important;
  box-shadow: none;
  border: 1px solid#f59e0bb3 !important;
  font-weight: bold;
  color: #000 !important;
  font-size: 12px;
  border-radius: 0.125rem;
  font-weight: 400;
}

.theme_dark_btn:hover,
.green-btn:hover {
  background: #ffcc2f !important;
  color: #333;
  border-color: #cb8009;
}

.agent-path ul {
  display: inline-block;
  padding: 5px 5px;
  line-height: 1.5;
  background: transparent;
  /* border: none; */
  border-radius: 0.375rem !important;
  border-left-width: 1px !important;
  /* --tw-border-opacity: 1 !important; */
  border: rgba(209, 213, 219, 1) thin solid !important;
  padding-left: 0.5rem !important;
}

.agent-path a {
  color: #000;
  font-size: 16px;
  text-decoration: none;
}

.find-member-director .btn:last-child {
  margin: 0;
}

.account-table td .btn {
  margin: 0 2px;
}

.risk-management-table .col-md-4 .green-btn {
  padding: 8px !important;
}

.find-member-director .btn:last-child {
  margin: 0;
}

.account-table td.action_link .btn:hover,
.theme_light_btn:hover {
  background-image: linear-gradient(178deg, #ddd, #fff);
  border-color: #ddd;
  box-shadow: none;
  color: #333;
}

.main-header .navbar-nav .nav-link.active1:hover {
  text-decoration: none;
}

.top-header-owner li:last-child {
  margin-right: 0px;
}

.breadcum-sec marquee span {
  display: inline-block;
  background: var(--primary);
  padding: 3px 10px;
  margin-right: 10px;
  font-size: 13px;
  border-radius: 4px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.account-table .responsive {
  overflow-x: auto;
  width: 100%;
}

.main-header-time-zone li a:hover {
  color: var(--secondary);
}

.account-table td a.btn .swap-icon {
  transform: rotate(90deg);
}

.fa-plus-square {
  color: var(--primary);
  margin-left: 4px;
  font-size: 14px;
}

.fa-minus-square {
  margin-left: 4px;
  font-size: 14px;
}

.agent-path li span {
  background: #fbcfe8;
  color: #9d174d;
  padding: 0px 4px;
  text-decoration: none;
  display: inline-block;
  margin-right: 8px;
  border-radius: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.agent-path li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
}

.agent-path {
  overflow-y: auto;
}

.main-inner-outer {
  /* padding: 15px 0 !important; */
}
.exposure-content h2 {
  font-size: 18px;
  color: #3b5160;
  font-weight: 500;
}

.exposure-content table th {
  font-size: 12px;
  font-weight: 400;
  background: #000;
  background-color: #e4e4e478;
  font-weight: 500;
  padding: 4px 10px;
}

.exposure-content table th,
.exposure-content table td {
  border: 1px solid #c3c3c391;
}

.exposure-content table td {
  color: #000;
  text-align: center;
}

.modal-title-status {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.change-status-modal .modal-content {
  background-color: #fff;
}

.change-status-modal .status-row {
  border-bottom: 1px solid #00000014;
  width: 100%;
  padding: 13px 10px;
  display: block;
}

.status-row h6 span {
  background: #568bc8;
  color: #fff;
  padding: 4px 7px;
  border-radius: 6px;
  font-size: 10px;
}

.status-row h6 {
  font-weight: 400;
}

.status-row small {
  display: block;
  position: relative;
  background-color: #d1fae5;
  color: #065f46;
  border-radius: 0.125rem;
  padding-left: 1rem;
  padding-right: 0.6rem;
  font-size: 0.75rem;
  font-weight: 600;
}
.status-row small::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 100%;
  z-index: 2;
  background: green;
}
.changestatus-option ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.changestatus-option ul li {
  flex: 0 0 33.33%;
  max-width: calc(33.33% - 12px);
  text-align: center;
  border: 1px solid;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin: 0px 6px;
  padding: 12px;
  background: linear-gradient(358deg, #d9d9d9, #fff);
}

.changestatus-option ul li a {
  display: block;
  text-decoration: none;
  color: #333;
}

.changestatus-option ul li span {
  display: block;
}

.changestatus-option {
  padding: 24px 10px 10px 10px;
}

.changestatus-option ul li i {
  font-size: 18px;
}

.changestatus-option ul li.suspended {
  background-image: linear-gradient(-180deg, #db2828 0%, #921313 100%);
  color: #fff;
}

.changestatus-option ul li.suspended a {
  color: #fff;
}

.changestatus-option ul li.locked {
  background-image: linear-gradient(-180deg, #9ab6ce 0%, #536174 100%);
  color: #fff;
}

.changestatus-option ul li.locked a {
  color: #fff;
}

.changestatus-option ul li.locked {
  /* background: antiquewhite; */
  background-image: linear-gradient(-180deg, #9ab6ce 0%, #536174 100%);
  color: #fff;
}

.changestatus-option ul li.locked a {
  color: #fff;
}

.change-status-form .f-group {
  flex: 1;
  max-width: initial;
  margin-right: 15px;
}

.change-status-form .green-btn {
  flex: 1;
  max-width: 100%;
  padding: 6px 10px;
}

.change-status-form .f-group .form-control {
  padding: 5px 15px;
}

.change-status-form .f-group .form-control::placeholder {
  font-size: 13px;
}

.change-status-form .f-group label {
  font-size: 14px;
}

.sidebar .sidebar-main ul li a {
  text-decoration: none;
  color: #222;
  font-size: 12px;
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid #e0e6e6;
}

.sidebar .sidebar-main ul li a:hover {
  color: #243a48;
}

.sidebar-main {
  background: #fff;
}

.sidebar .sidebar-main ul li a.active {
  color: #fff;
  padding: 0 10px;
  background: #333;
  line-height: 25px;
  padding: 0 10px;
}

.sidebar .sidebar-main ul li a.select {
  background-color: #dadada;
  color: #222;
  padding: 5px 10px;
  font-size: 12px;
}

.common-heading {
  color: #000000;
  margin-bottom: 5px;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  padding-left: 0.5rem;
}

.setting_dashboard_block .common-heading {
  margin: 0 0 4px;
  font-size: 14px;
}

.profile-tab .table th {
  padding: 6px 10px !important;
}



.common-tab .nav-tabs .nav-item .nav-link.active {
  background-color: #374151;
  color: #fff;
  box-shadow: none;
}

.common-tab .nav-tabs {
  border-bottom: 1px solid var(--secondary);
}

.bet_status .bet-sec {
  display: flex;
  align-items: center;
}

.form-select {
  border-radius: 0px;
  font-size: 13px;
}

.bet-sec .form-group {
  flex: 0 0 67%;
  max-width: 67%;
}

.bet_status .bet-sec .form-group {
  display: flex;
}

.bet_status .bet-sec.bet-period .form-group {
  flex: 0 0 78%;
  max-width: 78%;
}

.bet_status .bet-sec .form-group input[type="date"] {
  flex: 0 0 140px;
  max-width: 140px;
  margin-right: 10px;
  font-size: 14px;
}

.find-member-sec .small_form_control {
  height: 30px;
}

.bet_status .bet-sec .form-group .small_form_control {
  font-size: 12px;
  height: 30px;
}

.bet_status .bet-sec .form-group .form-control {
  font-size: 13px;
}

.history-btn {
  margin-top: 0.5rem !important;
  margin-bottom: 1rem !important;
}
.history-btn li {
  display: inline-block;
  margin-right: 16px;
}

.history-btn li a {
  text-decoration: none;
  border: 1px solid #bbb;
  display: block;
  padding: 7px 15px;
  border-radius: 6px;
  background: linear-gradient(180deg, #fff 0%, #eee 100%);
  color: #000;
  font-size: 12px;
  font-weight: bold;
}

.history-btn li a.active {
  background: #ffa00c !important;
  color: #333;
  border-color: #ddd;
}

.bet_status {
  position: relative;
  padding: 15px 10px;
  background-color: #f3f4f6;
  border-bottom: 1px solid rgba(75, 85, 99, 1);
  margin-bottom: 10px;
  border-top: 1px solid rgba(75, 85, 99, 1);
}

.batting-content {
  padding: 10px 2px;
  font-size: 14px;
}

.batting-content p {
  margin-bottom: 6px;
}

.small_select.form-select {
  border-radius: 0px;
  padding: 4px 10px;
  min-width: 170px;
  font-size: 12px;
  width: auto;
}

.form-select {
  border: 1px solid #aaa;
  box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 10%);
  margin: 0;
  border-radius: 4px !important;
}

.betting-profile-sec .form-select {
  flex: 0 0 56%;
  font-size: 14px;
  max-width: 56%;
  border-radius: 0px;
}

.betting-profile-sec form h4 {
  font-size: 16px;
  padding-left: 13px;
  margin: 0;
}

.betting-profile-sec form {
  flex: 0 0 26%;
  max-width: 26%;
}

.super-admin-form .form-label {
  font-size: 12px;
  display: block;
  text-align: right;
  font-weight: normal;
}

.super-admin-form .form-control,
.super-admin-form .form-select {
  font-size: 13px;
  border-radius: 4px;
  /* max-width: 190px; */
  /* height: 28px; */
}

.total-balance-summary dt {
  font-size: 15px;
  font-weight: 700;
  color: #3b5160;
}

.total-balance-summary strong {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  color: #2789ce;
}

.total-balance-summary strong sub {
  font-size: 15px;
  color: #7e97a7;
  font-weight: normal;
}

.total-balance-summary {
  border-bottom: 1px solid #7e97a7;
}

.profile-tab .table {
  width: 100% !important;
}

.exposure-content {
  width: 100%;
  overflow-x: auto;
}

.account-table .hide-tr td {
  background: var(--primary);
}

/* login-sec */

.login-sec {
  background-image: url(../images/login-back.jpg);
  height: 100vh;
  position: relative;
  z-index: 3;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-center {
  flex: 0 0 540px;
  max-width: 540px;
}

.login-sec .form_outer {
  padding: 20px;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.login-sec .form_outer form {
  flex: 1;
}

.login-sec form h2 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #000;
}

.login-sec form .green-btn {
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  margin: 15px 0 0;
  padding: 0;
  font-weight: bold;
}

.login-sec form .green-btn span {
  padding-left: 5px;
  filter: invert(1);
}

.login-sec form canvas {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-22%);
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
}

.form-control.is-invalid + canvas {
  transform: translateY(-40%);
}

.form-control.is-invalid {
  padding-right: 10px;
  background-image: none;
}

/* login-sec */

/* 27/9/22 */

.bet_status .green-btn {
  padding: 10px;
}

.bet_status .green-btn {
  padding: 7px 19px;
}

.form-check-input:checked {
  background-color: #2196f3;
  border-color: #2196f3;
}

.form-check-inline {
  font-size: 14px;
}

.bet-list-live .bet-sec label.form-label {
  flex: 0 0 45%;
  max-width: 45%;
}

.account-table caption {
  background-color: #3b5160;
  border-bottom: 1px solid #7e97a7;
  color: #fff;
  line-height: 24px;
  font-weight: 700;
  padding: 0 10px;
}

.block-modal-content h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
  text-align: center;
}

.form-switch .form-check-input {
  font-size: 26px;
  margin-left: 0 !important;
}

.account-table td .green-btn {
  background-image: linear-gradient(180deg, #fff 0%, #eee 100%);
  box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid #bbb;
  border-radius: 4px;
  color: #1e1e1e;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 10px;
  border-radius: 4px;
}

.set-limit-sec .row {
  padding: 20px 10px 20px;
  background-color: #e0e6e6;
  border-bottom: 1px solid #7e97a7;
  margin-bottom: 20px;
}

.set-limit-sec .row label {
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
}

.set-limit-sec .row .form-control {
  border-radius: 0px;
  padding: 3px 9px;
  font-size: 12px;
  height: 28px;
}

.change-password-sec label.invalid-feedback {
  flex: 0 0 100%;
  text-align: right;
  display: inline-block;
  width: 100%;
  max-width: 100%;
}

.account-table .green-btn {
  margin: 0 2px;
}

.change-password-sec .d-flex {
  flex-wrap: wrap;
}

.modal-header {
  padding: 0 0 10px 0 !important;
}

.risk-management-table {
  margin-bottom: 25px;
}

.risk-management-table .common-heading {
  color: #000000;
  margin-bottom: 5px;
  padding-left: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding: 0;
}
div#noData_MATCH_ODDS {
  background: rgba(226, 232, 240, 1) !important;
  padding: 1rem !important;
}

.match-odd-table table {
  vertical-align: middle;
}

/* .match-tbody td {
  border-right: 1px solid #fff;
} */
.match-tbody tr td {
  text-align: left;
  /* border-right: 1px solid #7e97a7 !important; */
  line-height: 1;
}

.selection-table td {
  border: 1px solid #7e97a7 !important;
  padding: 2px 10px;
}

.back-blue {
  padding: 8px 10px;
  background: rgb(147, 197, 253);
  /* border-radius: 10px 0px 0px 0px; */
  text-align: center;
}

.gray-inner_table {
  padding: 0px 0px 10px 46px !important;
  background-color: rgba(231, 229, 228, 0.5) !important;
}

.selection-table td.border-0 {
  border: none !important;
}

.match-tbody tr td.gray-inner_table tr:first-child td {
  background: #e2e8ed;
}

.lay-all {
  padding: 8px 10px;
  background: #fca5a5;
  /* border-radius: 0px 10px 0px 0px; */
  text-align: center;
}

.middle-blue {
  background-color: lightseagreen;
  padding: 6px 10px;
  text-align: center;
}

.light-blue {
  padding: 6px 10px;
  background-color: #72bbef;
  text-align: center;
  line-height: 1.2;
}

.dark-pink {
  padding: 8px 10px;
  background-color: lightpink;
  text-align: center;
}

.back-all-size {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.angle-up {
  background-image: linear-gradient(180deg, #fff 0%, #eee 100%);
  box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid #bbb;
  border-radius: 4px;
  color: #1e1e1e;
  font-weight: bold;
  font-size: 12px;
  line-height: 11px;
  padding: 0px 7px;
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.angle-up:hover {
  background: linear-gradient(180deg, #eee 0%, #fff 100%);
  color: #333;
  border-color: rgb(124, 124, 124);
}

.down-up i {
  transform: rotate(-180deg);
}

.selection-table tbody tr:first-child td,
.gray-table {
  background: #efefef;
}

.gray-table {
  background: #efefef !important;
}

/* 27/9/22 */

/**========================RR==================**/
.bank_balance_detail h6 {
  padding: 8px 10px 8px 0;
  border-right: 1px solid rgba(107, 114, 128, 1);
  margin-right: 10px;
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.bank_balance_detail strong {
  font-weight: 400;
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  color: #000;
}

.bank_balance_detail strong small {
  font-weight: 400;
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  color: #000;
  margin-right: 10px;
}

.bank_balance_detail {
  padding: 6px 20px;
}

.banking_detail_table td {
  vertical-align: middle;
  color: #1e1e1e;
}

.banking_detail_table td .form-control {
  font-size: 14px;
  height: 28px;
  padding: 6px 10px !important;
  border-radius: 4px !important;
}

.list_number {
  margin-right: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.medium_width {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  margin: auto;
}

.deposite-withdraw {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exposure_modal .modal-content {
  width: 640px;
  max-height: 640px;
  overflow: auto;
}

.dw-value_text_box {
  position: relative;
  margin: 0 10px;
}

.dw-toggle {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  width: 80px;
  height: 32px;
  justify-content: space-between;
  overflow: hidden;
}

.dw-toggle .tgl_btn {
  position: relative;
  padding: 0;
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.dw-toggle .tgl_btn input[type="radio"] {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.dw-toggle .tgl_btn label {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  background: #fffbeb;
  border: 1px solid #9ca3af80;
  color: #111827;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  /* margin-right: -1px; */
  cursor: pointer;
}

.dw-toggle .tgl_btn label.bg-red {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  margin-left: -2px;
}

.dw-toggle .tgl_btn input[type="radio"]:checked + .bg-green {
  background: #5bab03;
  color: #fff;
}

.dw-toggle .tgl_btn input[type="radio"]:checked + .bg-red {
  background: #d0021b !important;
  color: #fff;
}

.dw-graph-position {
  position: absolute;
  left: 10px;
  z-index: 2;
  font-size: 22px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: 4px 8px;
  line-height: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dw-value_text_box input {
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  padding: 6px 10px !important;
}

.paymoney_form .form-control {
  max-width: 150px;
}

.paymoney_form .form-control {
  max-width: 150px;
}

.clear_btn.btn {
  background: #dddddd;
  padding: 6px 24px;
  color: #000;
  border: 1px solid #bdbdbd;
}

.paymoney_form .form-control {
  max-width: 150px;
  margin: 0 15px;
}

.clear_btn.btn {
  background: #dddddd;
  padding: 6px 24px;
  color: #000;
  border: 1px solid #bdbdbd;
}

.paymoney_form .btn {
  padding: 7px 20px;
  font-size: 15px;
}

.paymoney_form .form-control {
  max-width: 200px;
  padding: 7px 15px !important;
  border-radius: 6px !important;
}

.clear_btn.btn {
  background: #dddddd;
  padding: 6px 24px;
  color: #000;
  border: 1px solid #bdbdbd;
}

.paymoney_form .btn {
  padding: 7px 20px;
  font-size: 15px;
}

.paymoney_form .form-control {
  max-width: 200px;
  padding: 7px 15px !important;
  border-radius: 0.125rem !important;
  background: rgba(254, 243, 199, 1);
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.2) !important;
  margin: 0 5px;
  text-align: center;
}

.clear_btn.btn {
  background: #f3f3f3;
  padding: 6px 24px;
  color: #000;
  border: 1px solid #e1e1e1;
}

.paymoney_form .btn {
  padding: 4px 20px;
  font-size: 12px;
}

.paymoney {
  padding: 20px 15px 0px 15px;
  margin: 0;
  background: #fff;
  border-top: #4b5563 thin dashed;
}

.payment_count {
  margin: 0 10px;
  background: rgba(251, 146, 60, 1);
  padding: 0px;
  border-radius: 100px;
  min-width: 15px;
  display: inline-block;
  font-size: 12px;
  color: #fff;
}

.setting_dashboard_block > ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0 -6px;
}

.setting_dashboard_block > ul > li {
  list-style: none;
  line-height: 25px;
  margin: 0 5px 5px 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.setting-dash > li {
  width: 12%;
  border: #000 2px solid;
}
.setting_dashboard_block > ul > li input[type="radio"] {
  width: auto !important;
  height: auto !important;
  border-width: 0;
  border-radius: 0;
  background-color: initial !important;
  padding: 0 !important;
  box-shadow: none;
  margin: 0 8px;
}
.setting_dashboard_block > ul > li label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.setting_dashboard_block > ul > li figure {
  margin: 0;
}

.setting_dashboard_block {
  padding: 15px;
  background-color: #e0e6e6;
  border-bottom: 1px solid #7e97a7;
  margin-bottom: 10px;
}

.hide-tr-class .fa-chevron-down {
  transform: rotate(-180deg);
}

.find-member-sec form .search-btn:hover {
  border-color: var(--secondary);
}

.humburger-open {
  background: #000;
  color: #fff;
  padding: 8px 12px;
  margin-left: 14px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.humburger-open:hover {
  background: var(--primary);
}

.change-password-sec .form-label {
  font-size: 13px;
  min-width: 156px;
  margin: 0;
  text-align: right;
  padding-right: 17px;
}

.change-password-sec .form-control {
  font-size: 12px;
  height: 30px;
  border-radius: 6px;
  /* max-width: calc(100% - 80px); */
  margin-left: 0;
  margin-right: auto;
  flex: 1;
}

.surveliance-setting-sec ul > li {
  flex: 0 0 14%;
  max-width: calc(14% - 12px);
}

.find-member-sec .form-label {
  font-size: 0.75rem;
  color: #374151;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 5px !important;
}

a.green-btn {
  text-decoration: none;
}

.live-match-bat a {
  padding: 7px 15px;
}

.top_player-wrap ul li {
  display: inline-block;
}

.top_player-wrap ul li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  padding: 6px 10px;
  display: inline-block;
  font-weight: 400;
  background-color: rgba(229, 231, 235, 1);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  margin-right: 10px;
  padding-bottom: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.top_player-wrap ul {
  background: transparent;
  border-bottom-width: 1px;
  border-bottom: rgba(55, 65, 81, 1) thin solid;
  font-size: 1rem;
}

.top_player-wrap ul li a.active {
  background: rgba(55, 65, 81, 1);
  color: #fff;
}

.account-table.border_left {
  border-left: 1px solid #7e97a7 !important;
}

.top_player-wrap table a {
  padding-left: 10px;
  color: #000;
}

.top_player-wrap .text-danger {
  font-size: 13px;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #eee;
  color: #333;
  outline: none;
}

.form-select:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.history-btn li a:hover {
  background: linear-gradient(0deg, #eee 0%, #fff 100%);
  color: #333 !important;
  border-color: #888 !important;
}

.form-control {
  width: 100%;
  height: 36px;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid #aaa;
  box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 10%);
  margin: 0;
  border-radius: 0.125rem;
  padding: 5px;
}

.bottom-pagination ul {
  padding: 0px;
  text-align: center;
}

.bottom-pagination ul li {
  display: inline-block;
  padding: 0;
}

.bottom-pagination ul li a {
  text-decoration: none;
}

.bottom-pagination ul li .pagintion-li {
  background: #dbeafe !important;
  padding: 6px 15px;
  border-radius: 0;
  color: #000;
  border-top: 1px solid #d1d5db;
  vertical-align: text-bottom;
  border-bottom: #d1d5db thin solid;
}

.bottom-pagination {
  border-top: #4b5563 thin dashed;
  padding-top: 15px;
  margin-bottom: 0px;
}

.match-odd-table.account-table td a span {
  background: transparent;
  color: #568bc8;
  font-size: 12px;
  margin: 0 0 0 5px !important;
  padding: 0;
  font-weight: normal;
}

.bottom-pagination .previous a,
.bottom-pagination .next a {
  background: #fff;
  cursor: not-allowed;
  border: 1px solid #d1d5db;
  border-radius: 0px;
  line-height: 24px;
  font-size: 16px;
  padding: 4px 17px;
  color: #000;
}

.changestatus-option ul li.active {
  background: #4cbb17;
  color: #fff;
}

.changestatus-option ul li.active a {
  color: #fff;
}

.changestatus-option ul li.active {
  border-color: #4cbb17;
}

.witherror .invalid-feedback {
  position: absolute;
  left: auto;
  bottom: -19px;
  font-size: 12px !important;
  right: 0px;
  text-align: left;
}

.witherror {
  position: relative;
}

/* color-picker */

.color-picker-btn {
  position: fixed;
  right: 0px;
  top: 120px;
  background: var(--secondary);
  border-color: var(--secondary);
  border-radius: 0px;
}

.color-picker-btn:hover {
  background: #ffa00c !important;
  border: 1px solid #cb8009 !important;
}

.form-control-color {
  width: 3rem;
  height: 42px;
  padding: 0.375rem;
}

.add_match_head .green-btn {
  margin: 0 2px;
}

.anchorLog,
.anchorLog:hover {
  cursor: pointer;
  border-color: #f59e0bb3;
  background-color: #ffcc2f;
  color: #000 !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.find-member-sec .search-user-change-pass {
  padding:5px;
  color: white !important;
  background: black;
  border-radius: 3px;
}

.find-member-sec .search-user-change-status {
  /* padding: 6px 10px; */
  min-width: initial;
  font-size: 12px;
  width: 130px;
  margin-left: 5px !important;
  /* margin: 8px auto 0 auto; */
}

/* color-picker */
.bet-sec.bet-period.upl-date .form-group .form-control {
  flex: 1;
  max-width: 100%;
}

.bet-sec.bet-period.upl-date .form-label {
  margin-right: 10px;
}

form.super-admin-form {
  padding: 10px 30px;
}

.check_date span {
  display: inline-block;
  vertical-align: initial;
  color: #7e97a7;
  font-size: 11px;
  line-height: 15px;
  width: 80px;
  float: left;
  text-align: left;
}

.account-table .inner_table th {
  color: #333;
  border-color: #ddd;
  text-align: left;
}

.account-table .inner_table td {
  text-align: left;
}

.light_blue td,
.light_blue th {
  background-color: #f0f8ff !important;
}

.large_table_data th {
  border-top: none !important;
  font-weight: bold !important;
}

.large_table_data td:first-child,
.large_table_data th:first-child {
  border-left: 1px solid #ddd !important;
}

.light_blue td a,
.light_blue th a {
  color: #3b5160;
  color: #3b5160;
  font-weight: normal;
  line-height: 1.5;
  padding: 0 8px;
  background-color: rgba(94, 190, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #7e97a7;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  padding: 4px 10px;
}

.profit_loss_table .table01.table-pnl {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  border-bottom: 1px solid #7e97a7;
  margin-bottom: 15px;
}

.profit_loss_table > td {
  position: relative;
  padding: 0;
  border-top-color: #7e97a7;
  border-bottom: 1px solid #7e97a7;
}

.profit_loss_table .table-commission {
  width: 75%;
  margin-left: 23%;
  border-right: 1px solid #7e97a7;
  border-left: 1px solid #7e97a7;
  background-color: #e2e8ed;
  text-align: right;
}

.profit_loss_table .sum-pl {
  background-color: #E5E7EB !important;
  /* border-top: 1px solid #7e97a7; */
}
.sum-pl dt {
  width: 82%;
  padding: 0 10px 0px;
  color: #243a48;
  float: left;
  text-align: right;
  font-weight: normal;
}

.sum-pl dd {
  margin: 0;
  padding: 0 10px 5px;
  text-align: right;
}

.profit_loss_table table td {
  border-width: 0;
  padding: 3px 10px;
  text-align: left;
}

.profit_loss_table table td:first-child {
  text-align: left;
}

.profit_loss_table .table > :not(caption) > * > * {
  padding: 0rem 5px;
}

.profit_loss_table .table01.table-pnl th:not(.expand_wrap th) {
  text-align: right;
}

.profit_loss_table .table01.table-pnl th:first-child:not(.expand_wrap th) {
  text-align: left;
}
.profit_loss_table .expand_wrap {
  background-color: #F3F2F1;
  padding: 0;
}

.tabel-profit-loss-header{
  background: #E2E8F0 !important;
  color: black !important;
  padding: 7px !important;
}

.profit_loss_table .expand table .even td {
  background-color: #f2f4f7;
}
.profit_loss_table .expand .sum-pl td {
  background-color: #E5E7EB;
  border-top: 1px solid #7e97a7;
}

.profit_loss_table > td {
  position: relative;
  padding: 0;
  border-top-color: #7e97a7;
  border-bottom: 1px solid #7e97a7;
}

.red {
  color: #d0021b !important;
}

.casino-grand-total {
  border-top: 1px solid #7e97a7;
  background-color: #d9e4ec;
  font-weight: bold;
}
/**==============new-css===============**/

.login_box {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.login_left {
  flex: 1;
  position: relative;
}

/* .login_left:after {
  content: "";
  position: absolute;
  background: #3a8242eb;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
} */

.login_left figure {
  margin: 0;
}

.login_logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  max-width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.login_logo img {
  margin-right: 1.75rem;
  margin-bottom: 1.25rem;
  height: 5rem;
  width: 14rem;
}

.support-wrap {
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 20px;
  display: none;
}

.support-wrap .support-service {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid rgba(33, 16, 16, 0.3);
}

.support-wrap .support-service::before,
.support-wrap .support-service::after {
  content: "";
  display: block;
  flex: 1;
  align-self: flex-end;
  height: 1px;
  background-color: rgb(57 57 58);
  margin-bottom: -1px;
}

.support-wrap .support-service img {
  width: 25px;
  height: 100%;
}

.support-wrap .support-detail a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #fff;
  text-decoration: underline;
}

.support-wrap .support-service a.open::after {
  content: "";
  position: absolute;
  top: 100%;
  height: 10px;
  background-image: url("../images/support_arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: initial;
  pointer-events: none;
  display: block;
  width: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.support-wrap .support-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 0 10px;
}
.first_cols {
  display: inline-block;
  border-right: 1px solid #ddd;
  padding-right: 32px;
}

.first_cols {
  display: inline-block;
  border-right: 1px solid #ddd;
  padding-right: 32px;
}

.main-header .navbar-nav .dropdown-item {
  border-bottom: 1px solid #6a6a6a;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
}

.main-header .navbar-nav .dropdown-item.active,
.main-header .navbar-nav .dropdown-item:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.3);
}

.inner-wrapper .d-lg-flex {
  padding: 10px 10px 0;
  background-color: #e0e6e6;
}

.main-header .navbar-nav .dropdown-menu {
  padding: 0;
  background: #ddd;
}

.bet_status .col-lg-2 {
  flex: 0 0 auto;
  width: 25%;
}
.bet_status .col-lg-2 label {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.first_cols {
  display: inline-block;
  border-right: 1px solid #ddd;
  padding-right: 32px;
}

.main-header .navbar-nav .dropdown-item {
  border-bottom: none;
  color: #fff;
  font-size: 14px;
  font-size: 12px;
  font-weight: 400;
  padding: 0.75rem 0 0.75rem 0.75rem;
  white-space: break-spaces;
}

.breadcum-sec .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.main-header .navbar-nav .dropdown-menu {
  padding: 0;
  margin-top: 0px;
  background: #ffffff33;
}
.navbar-expand-xl .navbar-nav .dropdown-menu {
  position: relative;
}

.bet_status .bet-sec .form-group input[type="date"] {
  font-size: 12px;
  height: 30px;
}

.form-label {
  font-weight: bold;
}

.lv_1 {
  background: #d65d5d !important;
}

.btn-close {
  background: url("../images/close_pop.png");
  background-repeat: no-repeat;
  opacity: 1;
  background-size: 20px;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  overflow: hidden;
  background-position: center;
  padding: 0 !important;
  top: -10px;
  position: relative;
}

.modal-content {
  background-color: #eee;
  width: 400px;
  border-radius: 5px;
  padding: 0px;
  top: 50%;
}
.test-status {
  padding: 0 10px;
}

.curent-value {
  font-size: 14px;
  color: #333;
}

.curent-value {
  font-size: 14px;
  color: #333;
}

.change-password-sec label {
  flex: 0 0 80px;
  text-align: right;
  display: inline-block;
  width: 80px;
  max-width: 80px;
}

.curent-value {
  font-size: 14px;
  color: #333;
}

.change-password-sec label {
  flex: 0 0 80px;
  text-align: right;
  display: inline-block;
  width: 80px;
  max-width: 80px;
}

.change-password-sec .theme_light_btn {
  min-width: 110px;
  box-shadow: none;
}

.accout_cols_outer {
  display: flex;
}

.left_side {
  flex: 0 0 234px;
  max-width: 234px;
}

.right_side {
  padding-left: 15px;
  flex: 1;
}

.right_side .form-label {
  padding-right: 10px;
  padding-top: 10px;
}

.bg-light-yellow {
  background-color: #f3dfb0 !important;
}

.deposite-withdraw .theme_light_btn {
  border-color: #bbb;
}

.banking_detail_table.table {
  max-width: 100%;
}

.form-select {
  border: 1px solid #d1d5db;
  box-shadow: inset 0px 2px 0px 0px rgb(0 0 0 / 10%);
  margin: 0;
  border-radius: 0.125rem !important;
}

.border-x {
  border-left: 1px solid #d1d5dbcc;
  border-right: 1px solid #d1d5dbcc;
}

.add_website_section label.invalid-feedback {
  text-align: left !important;
}

.match-odds-table .bets .lay-2,
.match-odds-table .bets .back-3 {
  height: 50px;
}

.form-label {
  margin-bottom: 0 !;
}

.bet_outer .bet-sec {
  display: flex;
  align-items: center;
  padding: 10px;
  flex: 0;
}

.bet_outer .bet-sec label.form-label {
  flex: 1;
  max-width: initial;
  white-space: nowrap;
  margin: 0 10px 0;
  font-size: 12px;
  font-weight: normal;
}

.bet_outer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.bet_outer .bet-sec select {
  min-width: 120px;
  max-width: 120px;
}

@keyframes loadBar {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
    transform: scale(1.4);
  }

  100% {
    opacity: 0.1;
  }
}

.top-header-owner .loading-bar {
  width: auto;
  height: 23px;
  align-items: center;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0 10px 0 15px;
  border-radius: 4px 0 0 4px;
}

.top-header-owner .loading-bar span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #6c6c6c;
  /* margin-right: 6px !important; */
  opacity: 1;
  animation: loadBar 0.8s ease infinite;
  display: inline-block;
  padding: 0;
  padding: 3px 3px;
}
.settings-container {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  gap: 10px;
}

.settings-container .settings-container-inner {
  /* gap: 20px; */
  width: 100%;
  padding: 20px 13px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.settings-container > div h6 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px 16px;
  gap: 4px 16px;
}

.input-list ul li div {
  display: flex;
  align-items: center;
}

.input-group-list div input {
  height: 28px;
  border: 2px solid lightgrey;
  border-radius: 7px;
}
.input-group-list div input:first-child {
  width: 80%;
}
.input-group-list label {
  font-weight: 600;
  margin-bottom: 4px;
  margin-left: 7px;
}

.input-group-list div input::placeholder {
  padding-left: 5px;
}

/* .account-table .responsive table td a span {
  background: #9e06bd;
  color: #fff;
  padding: 1px 5px;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  margin-right: 8px;
} */

/*=== SportAnalytics ===*/
.main-box {
  /* background: #fff; */
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 5px;
  padding: 7px 15px;
  margin-bottom: 15px;
  min-height: 600px;
  overflow-x: hidden;
}
.in-play-left {
  background: transparent;
  padding: 0.5rem !important;
}
.main-in-play .top {
  background-color: #05956b;
}
.main-in-play .top {
  display: flex;
  height: auto;
  margin: -8px 0 0 -8px;
  width: calc(100% - -16px);
  padding: 3px 5px 0;
}
.main-in-play .top h4 {
  margin-bottom: 0;
  color: #fff;
}
.main-in-play .top h4 span {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 3px;
  padding-top: 2px;
  line-height: 14px;
  float: left;
  position: relative;
  max-width: 700px;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.float-right {
  float: right !important;
}
.main-analysis {
  position: relative;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.analysis-running-market .top {
  display: flex;
  height: auto;
  margin: 0;
  width: 100%;
  padding: 3px 10px;
  background-color: rgb(39 166 195);
}
.analysis-running-market .top {
  background-color: #243a48;
}
.toptitle {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-top: 4px;
  line-height: 14px;
  float: left;
  position: relative;
  max-width: 700px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: #fff;
}
.bk-btn {
  color: #fff;
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  margin-left: 4px;
  line-height: 13px;
}
.bk-btn {
  color: #fff !important;
  background-color: #000;
}
.p-0 {
  padding: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.min-max {
  font-size: 11px;
  position: absolute;
  right: 5px;
  padding-top: 3px;
}
.min-max,
.toptitle {
  color: #fff;
}
.min-max {
  font-size: 11px;
  position: absolute;
  right: 5px;
  padding-top: 3px;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.bet-all-new,
.bet-all-new td {
  line-height: 22px !important;
}
.analysis-running-market .w-55 {
  width: 50%;
}
.analysis-running-market .w-90 {
  width: 90% !important;
}
.text-gray {
  color: #6a6a6a !important;
}
.bet-all-new td {
  padding: 0 !important;
}
.analysis-running-market table tr td,
.analysis-running-market table tr th {
  border-top: 1px solid #dee2e6;
}
.float-left {
  float: left !important;
}
.w-50 {
  width: 50% !important;
}
.bet-all-new td .lh22 {
  min-height: 22px !important;
}
.bookmakerbs .bid {
  width: calc(33.33% - 4px);
  border-radius: 0;
  padding: 2px 3px;
  float: left;
  margin: 0 2px;
  min-height: 36px;
}

.w-95 {
  width: 95% !important;
}
.widt50fleft-toss {
  width: 100% !important;
}
.bookmakerbs .bid-toss {
  width: 100% !important;
}
.f11 {
  color: #000;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
}
.bookmakerbs .ask {
  width: calc(33.33% - 4px);
  border-radius: 0;
  padding: 2px 3px;
  float: left;
  margin: 0 2px;
  min-height: 36px;
}
.bid {
  width: calc(50% - 4px);
  background: #8dd2f0;
  border-radius: 0;
  padding: 2px 3px;
  float: left;
  margin: 0 2px;
  line-height: 10px;
  min-height: 36px;
}
.bg-trans {
  background: transparent !important;
}
.bets-GH .back-all,
.bets-HS .back-all,
.bets .back-all {
  background-position: -274px -273px;
}
.bets .back-all,
.bets .lay-all {
  background-image: url(https://images.staticcontent.io/sex/aimg/main-s1.png);
}
.bets .ask1,
.bets .bid1 {
  background-color: transparent !important;
}
.bets-GH .lay-all,
.bets-HS .lay-all,
.bets .lay-all {
  background-position: 100% -399px;
}
a:not([href]),
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}
.ask {
  background: #faa9ba;
}
.analysis-running-market .w-55 {
  width: 50%;
}
.analysis-running-market table tr td,
.analysis-running-market table tr th {
  border-top: 1px solid #dee2e6;
}
.analysis-running-market table tr td,
.analysis-running-market table tr th {
  padding: 2px 3px;
  vertical-align: top;
}
.analysis-running-market span.in-play-title {
  font-size: 14px;
  color: #000;
  text-align: left;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px !important;
  width: 155px !important;
  display: inline-block;
  margin-left: 3px;
  line-height: 16px;
  cursor: pointer;
}
.d-inline-block {
  display: inline-block !important;
}
.analysis-running-market .w-45 {
  width: 50%;
}
/* .analysis-running-market .w-90 {
  width: 90%;
} */
.widt50fleft {
  width: 50%;
  float: left;
}
.bookmakerbs .bid {
  width: calc(33.33% - 4px);
  border-radius: 0;
  padding: 2px 3px;
  float: left;
  margin: 0 2px;
  min-height: 36px;
}
.back-light-bg1 {
  background: #d7e8f4 !important;
}
.bid-price {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  line-height: 18px;
}
.bid-price,
.bid-price-small {
  text-align: center;
  display: block;
}
.bid-price-small {
  font-size: 10px;
  color: #43444a;
}
.bid-price-small {
  font-weight: 700;
}
.back-light-bg {
  background: #b7d5eb !important;
}
.bid {
  background: #72bbef;
}
.ask {
  background: #faa9ba;
}
.marg1 {
  margin-left: 1px !important;
}
.ask-price,
.ask-price-small {
  text-align: center;
  font-weight: 700;
  display: block;
}
.ask-price {
  font-size: 14px;
  color: #000;
  line-height: 18px;
}
.lay-light-bg {
  background: #efd3d9 !important;
}
.lay-light-bg1 {
  background: #f6e6ea !important;
}
.bmmassage {
  font-weight: 700;
  max-width: 100% !important;
  color: #b1031a;
}
.fancymassage {
  padding: 0 5px 0 3px;
  font-size: 10px;
  display: inline-block;
  float: left;
  max-width: 250px !important;
}
.bmmassage {
  font-weight: 700;
  max-width: 100% !important;
  color: #b1031a;
}
.fancybtn-new a {
  background-color: #243a48;
  color: #fff;
  height: auto;
  width: auto;
  font-size: 14px;
  text-align: center;
  padding: 5px 15px 5px 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.fancybtn-new a,
.fancybtn-new a:hover {
  color: #fff;
}
.fancybg {
  background-color: #243a48 !important;
}
ul.fancy-filter {
  list-style-type: none;
  background-color: #243a48;
  padding: 0 2px;
  display: inline-block;
  margin-bottom: 0px;
}
ul.fancy-filter li {
  border-right: 1px solid #fff;
  display: inline-block;
  margin-right: 2px;
  padding-right: 2px;
}
ul.fancy-filter li a.active,
ul.fancy-filter li a:hover {
  background-color: #00593f;
  background-image: linear-gradient(#00593f, #00593f);
  color: #fff;
}
.accordion-outer-item-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 25px;
  cursor: pointer;
}
.accordion-outer-item-inner div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  opacity: 0.7;
}
.accordion-outer-item-inner div span {
  width: 84%;
}

.accordion-outer-item-inner div .accordion-outer-item-inner-icon {
  width: 16%;
}
.center-box p {
  color: #fff;
  width: 100%;
  max-width: 100%;
  background-color: #05956b;
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 3px;
  padding-top: 2px;
  line-height: 14px;
  display: block;
}
.center-box p span {
  padding: 2px 0px 4px 6px;
  display: inline-block;
}
.count {
  color: #fff;
  font-size: 12px;
}
.count .two {
  background: #000;
  border: 1px solid #000;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.matched-btn {
  color: #fff;
  background-color: #000;
  background-image: linear-gradient(#545454, #000);
  border: 1px solid #000;
  padding: 5px 7px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}
a i {
  padding: 0 2px;
}
.text-white {
  color: #fff !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.ifc {
  display: inline-flex;
  align-items: center;
}
.bg-dark1 {
  background-color: #243a48 !important;
  color: #fff !important;
}
.fancybtn-new a,
.fancybtn-new a:hover {
  color: #fff !important;
}
ul.fancy-filter li a.active,
ul.fancy-filter li a:hover {
  background-color: #00593f;
  background-image: linear-gradient(#00593f, #00593f);
  color: #fff;
}
ul.fancy-filter li {
  display: inline-block;
  margin-right: 2px;
  padding-right: 2px;
  border-right: 1px solid #fff;
}
ul.fancy-filter li a {
  padding: 5px 9px;
  font-size: 13px;
  display: inline-block;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
}
.fancynew-budge {
  position: absolute;
  top: -6px;
  right: 0;
  border-radius: 0.25rem;
  padding: 0.25em 0.4em;
  line-height: 9px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}
.fancynew-budge {
  background: red;
  color: #fff;
}
.bk-btn {
  color: #fff;
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  margin-left: 4px;
  line-height: 13px;
}
.bk-btn {
  color: #fff !important;
  background-color: #000;
}
.btlock5 {
  padding: 5px !important;
  margin-left: 3px !important;
}
ul.fancy-filter li a.active,
ul.fancy-filter li a:hover {
  background-color: #045662;
  border-radius: 5px;
}
ul.fancy-filter li a {
  color: #fff !important;
}

.game-wrap {
  background-color: #fff;
  color: #000;
  box-shadow: 0 1px 6px rgb(0 0 0/9%);
  -webkit-box-shadow: 0 1px 6px rgb(0 0 0/9%);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
}
.analysis-running-market table tr td,
.analysis-running-market table tr th {
  border: 1px solid #dee2e6;
}
.fantitle {
  width: 49%;
  font-size: 12px;
  line-height: 13px !important;
  padding: 4px 0;
  cursor: pointer;
  display: inline-flex !important;
  font-family: "Roboto", sans-serif;
  justify-content: center;
}
.analysis-running-market div.runningf.in-play-title {
  font-size: 14px;
  color: #000;
  text-align: left;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 560px !important;
  width: 560px !important;
  display: inline-block;
  margin-left: 3px;
  line-height: 13px;
  cursor: pointer;
}
.count-budge {
  background: #000;
  border-radius: 0.25rem;
  padding: 0.25em 0.4em;
  line-height: 10px;
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  margin-top: 5px;
  position: relative;
  top: -2px;
  right: 0;
  float: right;
}
.dhide {
  display: none !important;
}
.fancytable .w-16 {
  width: 15.7%;
  position: relative;
  text-align: center;
}
.ballrunning-fancy {
  position: relative;
  width: 100%;
  float: left;
}
.ballrunning-fancy:after {
  position: absolute;
  /* content: "Ball Running"; */
  content: attr(datascustomattribute);
  width: 100%;
  right: 0;
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  z-index: 0;
  height: 100%;
  font-weight: 700;
  line-height: 33px;
  cursor: not-allowed;
  text-transform: capitalize;
}
.ballrunning-fancy:after,
.br-full:after,
.suspended-event:after,
.suspended-fancy:after {
  background: url(https://images.staticcontent.io/sex/aimg/fancy.png)
    rgba(0, 0, 0, 0.5);
}
.ask {
  width: calc(50% - 4px);
  background: #feafb2;
  border-radius: 0;
  padding: 2px 3px;
  float: left;
  margin: 0 2px;
  line-height: 10px;
  min-height: 36px;
}
.min-max-price {
  position: absolute;
  right: 10px;
  font-size: 11px;
  top: 2px;
  text-align: right;
}
.suspended-fancy:after {
  position: absolute;
  content: "Suspended";
  width: 100%;
  right: 0;
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  z-index: 0;
  height: 100%;
  font-weight: 700;
  line-height: 33px;
  cursor: not-allowed;
  text-transform: capitalize;
}
.ballrunning-fancy:after,
.br-full:after,
.suspended-event:after,
.suspended-fancy:after {
  background: url(https://images.staticcontent.io/sex/aimg/fancy.png)
    rgba(0, 0, 0, 0.5);
}
.suspended-fancy {
  position: relative;
  width: 100%;
  float: left;
}
.lottery-w25 .btn-back,
.lottery-w25 .btn-lay {
  background-color: #72bbef !important;
  border-left-color: #fff;
  color: #000;
  height: 36px;
  line-height: 36px;
  margin-right: 0;
  font-size: 14px;
}
.btn-back,
.btn-lay {
  color: #1e1e1e;
  margin-right: 1%;
  background-color: #72bbef;
  border-left-color: #fff;
}
.lottery-w25 {
  width: 35% !important;
}
.lottery-inplay {
  color: #000;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.lottery-w75 {
  width: 65% !important;
}
.mobile-minmax-winnerodds {
  font-size: 12px;
  color: #000;
  display: none;
}
.analysis-running-market span.in-play-title-winnerodds {
  line-height: 21px;
}
.fancyplus-icon {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  float: right;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
}
.footer-allBet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-allBet .bottom-pagination ul {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.betList-bottom {
  display: flex;
  width: 100%;
  padding-left: 10px;
  padding-bottom: 10px;
}
.select-active-list {
  width: 25%;
}

.ul-t span {
  background: #f95e63;
  color: #fff !important;
  border-radius: 6px;
  padding: 5px 10px;
  font-weight: 600;
}

.ul-t2 span {
  background: #018200;
  color: #fff !important;
  border-radius: 6px;
  padding: 5px 10px;
  font-weight: 600;
}

.home-table .action_link a:nth-child(1) {
  background: #018200;
  color: #fff !important;
}
.home-table .action_link a:nth-child(2) {
  background: #fd7e4f;
  color: #fff !important;
}
.home-table .action_link a:nth-child(3) {
  background: #48ef32;
  color: #000 !important;
}
.home-table .action_link a:nth-child(4) {
  background: #fcff00;
  color: #000 !important;
}
.home-table .action_link a:nth-child(5) {
  background: #fea500;
  color: #000 !important;
}

.home-table .action_link a:hover {
  color: #000 !important;
}

.account-table.home-table td a span {
  background: #074b53;
}

.account-table.home-table td a .status-suspend1 {
  background: #f95e63;
  color: #fff !important;
  border-radius: 6px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 11px;
}
.account-table.home-table td a {
  font-size: 12px;
  color: #000 !important;
}
.account-table.home-table td {
  border: 1px solid #e1e6e7 !important;
  padding: 10px 10px;
  vertical-align: middle;
  background-color: #fff;
  font-weight: 600;
}

.topnav p {
  cursor: pointer;
}
.topnav {
  /* margin-top: 10px; */
  background-color: rgb(30 30 30);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
  margin: 0;
  padding: 0;
  /* height: 50px; */
}

.topnav p {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 2px 2px;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 0;
}

.topnav p:hover {
  border-bottom: 2px solid white;
}

.topnav p.active {
  border-bottom: 2px solid white;
  font-weight: 700;
}

/* accordian ------- */

.accordion-outer {
  position: absolute;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: 0px !important;
  border-radius: 0px;
  height: 100%;
  background: linear-gradient(180deg, #fdb72f 8%, #fdb72f 100%);
  z-index: 9;
  /* top: 134px; */
  width: 250px;
  scrollbar-width: none;
}
.accordion-outer .accordion-item .accordion-header button {
  font-size: 14px !important;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: thin solid black;
  border-left: none;
  border-right: none;
  padding: 5px;
}

.accordion-outer .accordion-item {
  border-radius: 0px;
  background-color: transparent;
}

.accordion-outer .accordion-button {
  background-color: transparent;
  padding: 6px !important;
}
.accordion-outer .accordion-button:hover {
  background: #d5ab59;
}

.accordion-outer .accordion-body {
  padding: 6px 13px !important;
}

.accordion-outer-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.accordion-outer-item div {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.accordion-outer-item div span {
  margin-left: 5px;
  font-size: 13px;
}
.accordion-outer .accordion-item .show {
  background-color: lightgrey;
}
button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-outer .accordion-button:not(.collapsed) {
  color: #000;
  background-color: 0c9cb3;
  box-shadow: none;
}

.modal-fullscreen-2xl {
  max-width: 100% !important;
}
.modal-fullscreen-2xl .modal-content {
  width: 100% !important;
}

.allbet-tr {
  background: #243a48 !important;
  color: white !important;
}

.App > div + div {
  margin-left: 16.666667%;
  min-width: 1030px;
  margin-top: 61px;
  position: relative;
  width: 83.333333%;
  background: #f2f2f2;
}

.main-header {
  background: #000 !important;
  color: #fff;
  height: 100vh;
  position: fixed;
  white-space: break-spaces;
  width: 16.666667%;
  /* min-width: 250px; */
  z-index: 999;
  overflow-y: auto;
  top: 0px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.logo {
  width: 100%;
  margin-bottom: 12px;
}

.side-logo {
  margin: auto;
  width: 75%;
  max-width: 260px;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: none !important;
}
.navbar-expand-xl .navbar-nav {
  flex-direction: column !important;
  width: 100%;
}

.modal-header {
  background: #ffcc2f;
  padding: 18px !important;
  color: #000;
}
.footer-sticky {
  position: sticky;
  bottom: 0px;
  justify-content: end;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: #4b5563 thin dashed;
}
.footer-sticky p {
  margin-bottom: 0;
  color: #000;
}
.btn-light {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  --tw-border-opacity: 0.2;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.user_data_div {
  border-radius: 0.375rem;
  border: rgba(209, 213, 219, 1) thin solid;
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
  display: inline-block;
  width: calc(100% - 2%);
}

.user_data_div ul.menu-list.list-unstyled {
  border-bottom: #374151 thin solid;
  display: flex;
}

.user_data_div ul.menu-list.list-unstyled li {
  display: inline-block;
  margin-right: 8px;
}

.user_data_div .sidebar .sidebar-main ul li a {
  text-decoration: none;
  color: #222;
  border-radius: 4px 4px 0 0;
  display: block;
  padding: 3px 16px;
  background: rgb(229, 231, 235);
  font-size: 16px;
  line-height: 25px;
  border: none;
}

.user_data_div .sidebar .sidebar-main ul li a.select {
  background-color: #374151;
  color: #fff;
}
.banking-table th,
.banking-table td {
  padding: 0.5rem !important;
  border: rgba(209, 213, 219, 1) solid thin !important;
}

.banking-table td .btn {
  /* margin: 0 2px; */
  box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid rgba(180, 83, 9, 0.2);
  border-radius: 4px;
  color: #1e1e1e;
  /* font-weight: bold; */
  font-size: 12px;
  /* line-height: 28px; */
  padding: 0.25rem 0.5rem;
  margin-right: 10px;
  background: rgba(255, 251, 235, 1);
  width: 58px;
}
.banking-table td .btn:hover {
  background-color: rgba(254, 243, 199, 1);
}

.banking-table .btn.disabled {
  cursor: not-allowed !important;
  color: rgba(107, 114, 128, 1) !important;
  border-color: rgba(107, 114, 128, 1);
  background-color: rgba(229, 231, 235, 1) !important;
  background-image: none !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.dw-toggle .tgl_btn label:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.dw-toggle .tgl_btn label.bg-green:hover {
  border-color: rgba(77, 124, 15, 1);
  background-color: rgba(101, 163, 13, 1);
}
.dw-toggle .tgl_btn label.bg-red:hover {
  border-color: rgba(153, 27, 27, 1);
  background-color: rgba(185, 28, 28, 1);
}
.super-admin-modal .modal-body {
  padding: 0;
  background: #fff;
}
.super-admin-modal .modal-content {
  width: 830px;
}
.useradd-h {
  background-color: #000;
  padding: 4px 10px;
  color: #fff;
  text-align: right;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.useraddbtn {
  border-top: rgba(75, 85, 99, 1) thin dashed;
  padding-top: 10px;
  text-align: right;
  padding-right: 10px;
}
.user-add-form {
  min-height: 418px;
}
.user-add-form-row {
  border-bottom: 1px solid rgba(229, 231, 235, 1);
  margin: 10px 22px;
}
.super-admin-modal form.super-admin-form {
  padding: 10px 0px;
}
.super-admin-modal form.super-admin-form h6 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding: 0;
  margin-bottom: 15px;
}
.super-admin-form .form-label {
  font-size: 12px;
  display: block;
  text-align: right;
  font-weight: normal;
  margin: 0;
  color: #000;
}
.super-admin-form .col-md-4 {
  align-items: start;
  display: flex;
  justify-content: end;
  padding-right: 0px;
  padding-top: 4px;
}

.super-admin-modal .super-admin-form .form-control {
  max-width: 100% !important;
  margin-bottom: 5px;
}
.super-admin-modal.modal.show .modal-dialog {
  max-width: 830px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: calc(100% - 1.75rem * 2);
}
.badge-pl {
  background: rgba(253, 230, 138, 1) !important;
}

.user-test.mb-2 {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.user-test.mb-2 strong {
  font-weight: 400;
}

.match-odd-table-new .responsive table tbody {
  border: 1px solid rgba(229, 231, 235, 1) !important;
}
.match-odd-table-new .responsive table .header-new tr td {
  background: rgba(75, 85, 99, 1);
  color: white;
  padding: 9px 10px !important;
}

.text-blue-new {
  color: #2779bf;
}
.btn-light-view {
  background-color: rgba(255, 251, 235, 1);
  border: 1px solid rgba(180, 83, 9, 0.2);
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
  width: 100%;
  padding: 10px 0;
  border-radius: 0.125rem;
  box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000),
    var(0 1px 2px 0 rgba(0, 0, 0, 0.05));
}

.back-1s .light-blue {
  background-color: #93c5fd !important;
}
.back-2 .light-blue {
  background-color: #bfdbfe !important;
}
.back-3 .light-blue {
  background-color: #dbeafe !important;
}

.lay-2 .dark-pink {
  background-color: #fecaca !important;
}

.lay-3 .dark-pink {
  background-color: #fee2e2 !important;
}
.table-commission-parlay tbody .header th {
  background: rgba(226, 232, 240, 1);
  color: black;
}

.table-commission-parlay tbody .even td {
  background: white !important;
}

.table-current-header th {
  padding: 7px !important;
}

.btn-highlight {
  background-color: #ffcc2f;
  border: 1px solid rgba(245, 158, 11, 0.7);
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  font-size: 13px;
  padding-bottom: 0.25rem;
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), */
  /* var(--tw-ring-shadow, 0 0 #0000), 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
}

.pop-title {
  background: #ffcc2f;
  padding: 10px;
}
.pop-title span {
  font-size: 18px;
}
.title-dark svg{
 
    height: 1.25rem;
    width: 1.25rem;

}
.title-lightdark tr th{
  padding-left: 10px;
}
.bg-blue-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}
.w-4 {
  width: 1rem !important;
}
.bg-red-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}
.tempTr{
  --tw-border-opacity: 1;
  border-bottom:1px solid rgba(229, 231, 235, var(--tw-border-opacity));
}
.tempTr td{

    padding: 0.5rem;

}
.link-default{
  color: #2779bf !important;
}
.pop-wrap {
  background: #ffcc2f;
  display: flex;
  align-items: center;
  color: black;
}
.xl-title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  display: flex;
  align-items: center;
}

.pop-wrap-inner {
  height: calc(100% - 56px);
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.title-dark,
.title-dark th {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.title-lightdark,
.title-lightdark th {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.border-r {
  border-right-width: 1px !important;
}


.bg-yellow-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  color: black !important;
}


.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.input-new {
  padding: 0.25rem;
  font-size: 0.75rem;
  height: 1.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  border-radius: 0.125rem;
  border-width: 1px;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.label-new {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

/* input, select, textarea, [type="text"], [type="password"] {
  display: block;
  height: 1.75rem;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.125rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
} */
.common-tab .nav-tabs .nav-item .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background: rgb(229, 231, 235);
  /* border: 1px solid #3b5160; */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #3b5160;
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
  margin-bottom: 0;
}

/* .tab .active {
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
} */

.total-banking td{
    background: rgba(255, 237, 213, 1) !important;
}